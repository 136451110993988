export function swiper() {
  const swiperContainer = document.querySelector(".swiper-wrapper");
  if (!swiperContainer) return;
  const slides = swiperContainer.querySelectorAll(".swiper-slide");

  const slidesNeeded = 4 - slides.length;
  for (let i = 0; i < slidesNeeded; i++) {
    const emptySlide = document.createElement("div");
    emptySlide.classList.add("swiper-slide");
    emptySlide.textContent = "";
    swiperContainer.appendChild(emptySlide);
  }

  var swiper = new Swiper(".swiper", {
    // direction: "vertical",
    slidesPerView: 3,
    // spaceBetween: 12,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    on: {
      init: function () {
        updateNavigationVisibility(this);
      },
      slideChange: function () {
        updateNavigationVisibility(this);
      },
    },
  });

  function updateNavigationVisibility(swiper) {
    const prevButton = document.querySelector(".swiper-button-prev");
    const nextButton = document.querySelector(".swiper-button-next");

    if (swiper.isBeginning) {
      prevButton.style.display = "none";
      nextButton.style.display = "";
    } else if (swiper.isEnd) {
      prevButton.style.display = "";
      nextButton.style.display = "none";
    } else {
      prevButton.style.display = "";
      nextButton.style.display = "";
    }
  }
}
