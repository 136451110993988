export function subscribeCheck() {
  if (!window.location.href.includes("/guides/")) {
    return;
  }

  let isPlayButtonPending = false;
  let isContentReady = false;
  let isUserAuthorizedToWatch = false;
  let iframe;
  const urlParams = new URLSearchParams(window.location.search);
  const autoShow = urlParams.get("autoshow") === "true";

  window.addEventListener("load", () => {
    // The window is fully loaded.
    // Start loading content into the iFrame and set up event listeners.
    const playerElement = document.getElementById("lessonPlayer");
    if (!playerElement) return;
    const playerUrl = playerElement.getAttribute("data-playerUrl");
    iframe = document.getElementById("lessonIframe");
    console.log("playerUrl: ", playerUrl);
    if (iframe && playerUrl) {
      iframe.src = playerUrl;
    }

    // Collect data to check if user is authorized to watch the guide.
    const isSubscriptionRequired = safeParse(playerElement.getAttribute("data-value"));
    const isUserSignedIn = safeParse(playerElement.getAttribute("data-is-signed-in"));
    const isSubscriptionValid = safeParse(playerElement.getAttribute("data-is-subscription-valid"));
    isUserAuthorizedToWatch = !isSubscriptionRequired || (isUserSignedIn && isSubscriptionValid);

    // If auto-show is enabled and the user is not authorized, show subscribe modal immediately.
    if (autoShow && !isUserAuthorizedToWatch) {
      showModalWithRetry("subscribeModal");
    }

    // Player reporting ready to accept & handle button clicks.
    window.addEventListener("message", (event) => {
      if (event.data === "contentReady") {
        isContentReady = true;
        if (autoShow || isPlayButtonPending) {
          handleGuidePlay();
        }
      }
    });

    // Set up play button click handler.
    document.getElementById("playButtonToHide")?.addEventListener("click", handleGuidePlay);

    // Set up close button click handler.
    document.getElementById("closeButton")?.addEventListener("click", () => {
      iframe?.contentWindow?.postMessage({ action: "clickCloseButton" }, "*");
      completionCheck();
    });
  });

  completionCheck();

  const handleGuidePlay = () => {
    if (isMobile()) {
      // On mobile devices, redirect to "/discover".
      window.open("/discover", "_blank");
    } else if (!isUserAuthorizedToWatch) {
      showModalWithRetry("subscribeModal");
    } else if (!isContentReady) {
      isPlayButtonPending = true;
    } else {
      showModalWithRetry("playerModal");
    }
  };

  // Show modal with retries until the modal element is found and initialized.
  function showModalWithRetry(elementId, retryCount = 5) {
    const modalElement = document.getElementById(elementId);
    if (!modalElement) {
      if (retryCount > 0) {
        return setTimeout(() => showModalWithRetry(elementId, retryCount - 1), 100);
      }
      return console.error(`Failed to find modal ${elementId} after multiple attempts.`);
    }
    try {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
      if (elementId === "playerModal") {
        iframe?.contentWindow?.postMessage({ action: "clickPlayButton" }, "*");
        isPlayButtonPending = false;
      }
    } catch (error) {
      console.error("Error initializing modal:", error);
      if (retryCount > 0) {
        setTimeout(() => showModalWithRetry(elementId, retryCount - 1), 100);
      }
    }
  }

  function safeParse(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error("Error parsing value:", value, error);
      return null;
    }
  }

  function completionCheck() {
    const seriesNameElement = document.getElementById("series-name");
    const guideNameElement = document.getElementById("guide-name");
    if (seriesNameElement && guideNameElement) {
      const seriesName = seriesNameElement.textContent.trim();
      const guideName = guideNameElement.textContent.trim();

      // Execute the fetch only if both seriesName and guideName have been initialized properly.
      if (seriesName && guideName) {
        fetch(`/api/guidesComplete/${seriesName}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data?.completedGuideNamesInSeries?.includes(guideName)) {
              console.log(`Guide "${guideName}" is completed.`);
              const completedTag = document.getElementById("completed-tag");
              if (completedTag) {
                completedTag.style.display = "inline";
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching series complete data:", error);
          });
      } else {
        console.error("Series name or guide name is empty.");
      }
    } else {
      console.error("Required elements (series-name and guide-name) were not found.");
    }
  }
}
