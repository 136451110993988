import { swiper } from "./components/swiper/swiper.js";
import { filter_dropdown } from "./components/filter-dropdown/filter-dropdown.js";
import { favorite } from "./components/favorite/favorite.js";
import { subscribeCheck } from "./components/subscribe/player-subscribe.js";
import { chargebee_hosted } from "./components/chargebee-hosted/chargebee-hosted.js";
import { searchValidation } from "./search-validation.js";
import { updateSeriesCompleteStatus } from "./components/series/series-complete.js";
import { updateAllSeriesCompleteStatus } from "./components/all-series/all-series-complete.js";
import { docuLoader } from "./components/docu-loader/docu-loader.js";

document.addEventListener("DOMContentLoaded", () => {
  swiper();
  favorite();
  filter_dropdown();
  subscribeCheck();
  chargebee_hosted();
  searchValidation();
  updateSeriesCompleteStatus();
  updateAllSeriesCompleteStatus();
  docuLoader();
});
