export function updateSeriesCompleteStatus() {
  if (!window.location.href.includes("/series/")) {
    return;
  }

  const seriesName = document.getElementById("seriesTitle").textContent.trim();
  fetch(`/api/guidesComplete/${seriesName}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      data.completedGuideNamesInSeries.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          // Change the display style from 'none' to 'block'
          element.style.display = "block";
        } else {
          console.warn(`Element with id ${id} not found.`);
        }
      });
    })
    .catch((error) => {
      console.log("Error fetching series complete data:", error);
    });
}
