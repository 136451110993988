export function searchValidation() {
  // Validate the search query against our rules.
  function validateQuery(query) {
    const minLength = 3;
    const maxLength = 200;

    // Trim whitespace from the query.
    query = query.trim();

    // Check minimum length.
    if (query.length < minLength) {
      alert("Your search query must be at least " + minLength + " characters long.");
      return false;
    }

    // Check maximum length.
    if (query.length > maxLength) {
      alert("Your search query must be no more than " + maxLength + " characters long.");
      return false;
    }

    // Define allowed characters regex.
    // This example allows letters, digits, spaces, hyphens, underscores, periods, asterisks (wildcards), and question marks.
    const allowedRegex = /^[a-zA-Z0-9\s\-\_\.\\\*\?]+$/;
    if (!allowedRegex.test(query)) {
      alert(
        "Your query contains invalid characters. Allowed characters include letters, numbers, spaces, -, _, ., *, and ?.",
      );
      return false;
    }

    // Optionally, limit the number of wildcard characters (e.g., '*' characters).
    const maxWildcards = 3;
    const wildcardCount = (query.match(/\*/g) || []).length;
    if (wildcardCount > maxWildcards) {
      alert(
        "Too many wildcard characters in your query. Please limit them to " +
          maxWildcards +
          " per search.",
      );
      return false;
    }

    // All validations passed.
    return true;
  }

  // Attach the event listener to the form only if the element exists.
  if (typeof headerPartial !== "undefined" && headerPartial) {
    headerPartial.addEventListener("submit", function (e) {
      // Check if the submitted form is our search form.
      // Here we assume the search form has role="search".
      if (e.target && e.target.matches("form[role='search']")) {
        var searchInput = e.target.querySelector('input[name="searchQuery"]');
        if (searchInput && !validateQuery(searchInput.value)) {
          // Prevent submission if validation fails.
          e.preventDefault();
        }
      }
    });
  }
}
