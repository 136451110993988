export function filter_dropdown() {
  document.addEventListener("click", function (e) {
    const target = e.target;
    const listItem = target.closest("li");

    if (!listItem) return;

    const svg = listItem.querySelector("#svg-rotate");
    const viewMoreBtn = listItem.querySelector("#viewMoreBtn");
    const viewLessBtn = listItem.querySelector("#viewLessBtn");

    const isViewMoreOrLess =
      target.classList.contains("view-more") ||
      target.classList.contains("view-less");

    const isSvgRotate = target.closest(".svg-rotate");
    const isSvgRotateSingle = target.closest(".svg-rotateSingle");

    if (isViewMoreOrLess || isSvgRotate) {
      svg.classList.toggle("rotate");
      viewMoreBtn.classList.toggle("d-none");
      viewLessBtn.classList.toggle("d-none");
    }

    if (isSvgRotateSingle) {
      svg.classList.toggle("rotate");
    }
  });
}
