export function docuLoader() {
  const docuPages = {
    faq: "faq.html",
    "terms-of-service": "Privacy-Policy.html",
    "privacy-policy": "Terms-of-Service.html",
    "our-philosophy": "Our-Philosophy.html",
  };

  const cdnUrl = "https://cgassets-eqcjb2deeyeufycr.z03.azurefd.net/documents/";
  const currentPath = window.location.pathname; // e.g., "/some/path/faq"

  // Split the path into segments and get the last one
  const segments = currentPath.split("/").filter(Boolean);
  const portalKey = segments[segments.length - 1];

  const blobPath = docuPages[portalKey];
  if (!blobPath) {
    return;
  }

  const docuUrl = `${cdnUrl}${blobPath}`;

  fetch(docuUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .then((data) => {
      const docuContainer = document.getElementById("docu-container");
      if (docuContainer) {
        docuContainer.innerHTML = data;
      } else {
        console.warn("docu-container not found.");
      }
    })
    .catch((error) => {
      console.log("Error fetching document:", error);
    });
}
