export function chargebee_hosted() {
  const urlParams = new URLSearchParams(window.location.search);
  const showModal = urlParams.get("showModal");

  if (showModal === "true") {
    const hostedPageUrl =
      "https://impresys.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=CG-Monthly-USD-Monthly&utm_source=cb-app-copy";

    /* TEST CODE START
    const testToken = "6Raqe1RlOll7t0cufTh4Kg7unZaLM531D";
    postSubscriptionToken(testToken);
    return;
    TEST CODE END */

    var cbInstance = Chargebee.getInstance();
    cbInstance.openCheckout({
      url: hostedPageUrl, // Use provided hostedPageUrl
      success: (response) => {
        postSubscriptionToken(response);
      },
      failure: (error) => {
        console.error("Subscription failed:", error);
      },
    });
  }
}

function postSubscriptionToken(checkoutToken) {
  fetch("/cb/checkout", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ checkoutToken: checkoutToken }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl; // Redirects the user
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function subscriptionFlowEnd(msg) {
  fetch("/cb/subscriptionFlowEnd", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message: msg }),
  }).catch((error) => {
    console.error("Error triggering sync:", error);
  });
}

const manageSubscriptionBtn = document.getElementById("manage-subscription-btn");

if (manageSubscriptionBtn) {
  manageSubscriptionBtn.addEventListener("click", function () {
    const cbInstance = Chargebee.getInstance();
    var cbPortal = cbInstance.createChargebeePortal();
    var callbacks = {
      close: function () {
        subscriptionFlowEnd("Portal closed");
      },
      subscriptionChanged: function (data) {
        subscriptionFlowEnd("Subscription changed");
      },
    };
    cbPortal.open(callbacks, {
      sectionType: Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
      params: {
        subscriptionId: "CG-Monthly-USD-Monthly",
      },
    });
  });
}
